"use client";
import { useContext, useEffect } from "react";
import { useDataLayerContext } from "./DataLayerContextProvider";
import { DataLayerEvents } from "./DataLayerContextProvider";
import { getPageViewInfo } from "@/lib/auth/auth-utils";
import { BigCommerceStoreContext } from "@/providers/BigCommerceStoreProvider";
import { useSessionContext } from "@/lib/auth/SessionContextProvider";

interface Props {
	pageType: PageType;
	userId?: number | undefined;
	companyName?: string | undefined;
	currencyCode?: string | null;
	accountNumber?: string | null;
}

type PageType =
	| "page"
	| "product"
	| "cart"
	| "checkout"
	| "company"
	| "accounts"
	| "informational"
	| "regional"
	| "blogs"
	| "news";

export default function PageViewEvent({ pageType }: Props) {
	const { push } = useDataLayerContext();
	const { currencyFormattingRules } = useContext(BigCommerceStoreContext);
	const session = useSessionContext();
	const userId = session.session?.customerId;

	useEffect(() => {
		const fetchDataAndSendEvent = async () => {
			try {
				const res = await getPageViewInfo(); // Adjust API endpoint
				const { companyName, accountNumber } = res;

				const eventPayload: Record<string, any> = {
					event: DataLayerEvents.PAGE_VIEW,
					userId: userId !== 0 ? userId : undefined,
					companyName,
					pageType,
					currencyCode: currencyFormattingRules?.currency ?? undefined,
					accountNumber,
				};

				push(eventPayload);
			} catch (error) {
				console.error("Error fetching company info:", error);
			}
		};

		fetchDataAndSendEvent();
	}, [userId, pageType]); // Runs when userId or pageType changes

	return null;
}
